import React from "react"

const Waves = () => {
  return (
    <div>
      <svg viewBox="0 0 800 622.3">
        <mask id="maskLayer">
          <rect width="800px" height="622px" fill="#fff" />
          <path d="M588,265.7c-67.9,0-136.6-30.5-204.1-90.6c-65.4-58.2-133.2-76.4-201.6-54c-50.7,16.6-85,50-86.3,51.3 c-20.6,21.2-54.7,21.7-75.9,1.2C-1.2,153-1.8,118.8,18.8,97.5c2.1-2.1,52-53.1,130.1-78.7c73.8-24.1,187-30.5,306.6,75.9  c52.2,46.5,101.2,67.6,145.6,62.7c55.9-6.1,90.9-52.2,92.4-54.2c17.1-23.7,50.7-29.2,74.7-12.1c24,17.2,29.7,50.7,12.7,74.8 c-2.5,3.5-61.9,86.3-167,98.3C605.2,265.2,596.6,265.7,588,265.7C588.1,265.7,588,265.7,588,265.7z" />
          <path d="M593.7,441.1c-67.9,0-136.6-30.5-204.1-90.6c-65.4-58.2-133.2-76.4-201.6-54  c-50.7,16.6-85,50-86.3,51.3C81,369,47,369.5,25.7,349c-21.3-20.6-21.9-54.8-1.3-76.1c2.1-2.1,52-53.1,130.1-78.7 c73.8-24.1,187-30.5,306.6,75.9c52.2,46.5,101.2,67.6,145.6,62.7c55.9-6.1,90.9-52.2,92.4-54.2c17.1-23.7,50.7-29.2,74.7-12.1 c24,17.2,29.7,50.7,12.7,74.8c-2.5,3.5-61.9,86.3-167,98.3C611,440.6,602.3,441.1,593.7,441.1C593.8,441.1,593.8,441.1,593.7,441.1z" />
          <path d="M593.7,609.6c-67.9,0-136.6-30.5-204.1-90.6c-65.4-58.2-133.2-76.4-201.6-54  c-50.7,16.6-85,50-86.3,51.3C81,537.5,47,538,25.7,517.5c-21.3-20.6-21.9-54.8-1.3-76.1c2.1-2.1,52-53.1,130.1-78.7 c73.8-24.1,187-30.5,306.6,75.9c52.2,46.5,101.2,67.6,145.6,62.7c55.9-6.1,90.9-52.2,92.4-54.2c17.1-23.7,50.7-29.2,74.7-12.1 c24,17.2,29.7,50.7,12.7,74.8c-2.5,3.5-61.9,86.3-167,98.3C611,609.1,602.3,609.6,593.7,609.6C593.8,609.6,593.8,609.6,593.7,609.6z" />
        </mask>

        <rect id="masked" width="800px" height="622px" fill="#fff" />

        <path
          id="CA3"
          className="st0"
          fill="#00adef"
          d="M49.3,136.5c2.1-2.1,52-53.1,130.1-78.7c73.8-24.1,187-30.5,306.6,75.9c52.2,46.5,101.2,67.6,145.6,62.7 c55.9-6.1,90.9-52.2,92.4-54.2c17.1-23.7,50.7-29.2,74.7-12.1c3.6,2.6,6.8,5.5,9.5,8.7c-2.7-12.7-9.9-24.5-21.3-32.7 c-24-17.1-57.6-11.6-74.7,12.1c-1.5,2-36.5,48.1-92.4,54.2c-44.4,4.9-93.4-16.2-145.6-62.7C354.6,3.3,241.4,9.7,167.6,33.8 c-78.1,25.6-128,76.6-130.1,78.7c-19.4,20.1-20,51.7-2.1,72.5C31.9,168.1,36.5,149.7,49.3,136.5z"
        />
        <path
          id="CA2"
          className="st0"
          fill="#00adef"
          d="M54.9,311.9c2.1-2.1,52-53.1,130.1-78.7c73.8-24.1,187-30.5,306.6,75.9c52.2,46.5,101.2,67.6,145.6,62.7 c55.9-6.1,90.9-52.2,92.4-54.2c17.1-23.7,50.7-29.2,74.7-12.1c3.6,2.6,6.8,5.5,9.5,8.7c-2.7-12.7-9.9-24.5-21.3-32.7 c-24-17.1-57.6-11.6-74.7,12.1c-1.5,2-36.5,48.1-92.4,54.2c-44.4,4.9-93.4-16.2-145.6-62.7c-119.6-106.4-232.8-100-306.6-75.9 c-78.1,25.6-128,76.6-130.1,78.7c-19.4,20.1-20,51.7-2.1,72.5C37.5,343.5,42.1,325.1,54.9,311.9z"
        />
        <path
          id="CA1"
          className="st0"
          fill="#00adef"
          d="M54.9,480.4c2.1-2.1,52-53.1,130.1-78.7c73.8-24.1,187-30.5,306.6,75.9c52.2,46.5,101.2,67.6,145.6,62.7 c55.9-6.1,90.9-52.2,92.4-54.2c17.1-23.7,50.7-29.2,74.7-12.1c3.6,2.6,6.8,5.5,9.5,8.7c-2.7-12.7-9.9-24.5-21.3-32.7 c-12.2-8.7-27-11.6-40.7-9.1c-9.3,6.1-19.6,12-30.9,17.3c-1.1,1.2-2.1,2.5-3.1,3.9c-1.5,2-36.5,48.1-92.4,54.2 c-44.4,4.9-93.4-16.2-145.6-62.7c-119.6-106.4-232.8-100-306.6-75.9c-78.1,25.6-128,76.6-130.1,78.7c-19.4,20.1-20,51.7-2.1,72.5 C37.5,512,42.1,493.6,54.9,480.4z"
        />
      </svg>
    </div>
  )
}

export default Waves
