import React from "react"
const WaveSingle = () => {
  return (
    <div className="wave-single-icon">
      <svg
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 250 102"
      >
        <path
          d="M179.1,89.5c-19.7,0-39.6-8.8-59.2-26.3c-19-16.9-38.6-22.2-58.5-15.7c-14.7,4.8-24.6,14.5-25,14.9	c-6,6.2-15.9,6.3-22,0.3C8.1,56.8,8,46.9,14,40.8c0.6-0.6,15.1-15.4,37.7-22.8c21.4-7,54.2-8.9,88.9,22	c15.1,13.5,29.3,19.6,42.2,18.2c16.2-1.8,26.4-15.1,26.8-15.7c5-6.9,14.7-8.5,21.7-3.5c7,5,8.6,14.7,3.7,21.7	c-0.7,1-18,25-48.4,28.5C184.1,89.4,181.6,89.5,179.1,89.5C179.1,89.5,179.1,89.5,179.1,89.5z"
          fill="#00adef"
        />
      </svg>
    </div>
  )
}
export default WaveSingle
